function reloadForms(){
    let visible = window.getComputedStyle(document.getElementById("feedbackPanel")).display === "none"
    if(!visible){
        $('html,body').animate({
            scrollTop: $('#feedbackPanel').offset().top - (headerLayerPosition.$mobileMenu.height() === 90 ? 250 : 100)
        }, 1000);
    }

    $forms.forEach(function (form) {
        var $submitButton = form.$el.find('#submitButton');
        var $nextPageButton = form.$el.find('#nextPageButton');
        var $prevPageButton = form.$el.find('#previousPageButton');

        function setLoadingButton($button, loading) {
            if (loading) {
                $button.addClass('loading');
            } else {
                $button.removeClass('loading');
            }

            $button.find('input, button').prop('disabled', loading);
        }

        function getFormCurrentPage() {
            return form.$el.find('.eforms-page.conditionally-visible:visible:first');
        }

        function applyValidationErrors(data) {
            // clear previous errors
            form.$el.find('.error').removeClass('error');

            // remove previous error messages from feedback panel
            var messagesList = form.$el.find('#feedbackPanel > ul');
            messagesList.empty();

            var count = 0;
            var $input;
            var $errorLabel;
            if (data) {
                for (var fieldName in data) {
                    count++;
                    $input = form.$el.find('*:input[name="' + fieldName + '"]');
                    $input.parents('.form-group').addClass('error');

                    $errorLabel = form.$el.find('#' + fieldName + '-error');
                    if ($errorLabel) {
                        $errorLabel.find('span').text(data[fieldName].localizedMessage);
                    }
                }
            }

            var $currentPage = getFormCurrentPage();
            var $antispam = $currentPage.find('.antispam .form-group');
            if ($antispam.length > 0) {
                var $antispamSlider = $antispam.find('.ui-slider');
                if ($antispamSlider.length > 0 && $antispamSlider.slider('value') < 50) {
                    $antispam.addClass('error');
                    count++;

                } else {
                    $antispam.removeClass('error');
                }
            }

            return count;
        }

        function initAntiSpamSlider() {

            var $formCurrentPage = getFormCurrentPage();
            var $antiSpamSlider = $formCurrentPage.find('.antispam .antispam-slider');

            if ($antiSpamSlider && $antiSpamSlider.length > 0) {
                if ($antiSpamSlider.data('ui-slider')) {
                    $antiSpamSlider.slider("destroy");
                }

                var sliderMax = 0;

                $antiSpamSlider.slider({
                    create: function (event, ui) {
                        var cursorWidth = form.$el.find('#slider .ui-slider-handle').outerWidth();
                        var sliderWidth = $antiSpamSlider.outerWidth();
                        sliderMax = 100 - (100 / sliderWidth) * cursorWidth;
                    }
                });

                $antiSpamSlider.on('slidestop', function (event, ui) {
                    var selection = $antiSpamSlider.slider("value");
                    if (selection >= 60) {
                        $antiSpamSlider.slider("value", sliderMax);
                        form.$el.find('#slided').css({'display': 'block'});
                        form.$el.find('#notSlided').css({'display': 'none'});
                        form.$el.find("#sliderInput").prop("value", sliderMax);
                    } else {
                        //commentato per l'accessibilitá https://euris-experience.atlassian.net/browse/SSD-3921
                        //$antiSpamSlider.slider("value", 0);
                        form.$el.find('#slided').css({'display': 'none'});
                        form.$el.find('#notSlided').css({'display': 'block'});
                        form.$el.find("#sliderInput").prop("value", "");
                    }
                });

                $antiSpamSlider.on('slide', function (event, ui) {
                    if (ui.value > sliderMax) {
                        event.stopPropagation();
                        event.preventDefault();
                    }
                });
            }
        }

        function resetPagesVisible() {
            var allPages = form.$el.find('.eforms-page.conditionally-visible');
            var curPage = getFormCurrentPage();
            var curIndex = -1;

            for (var i = 0; i < allPages.length; i++) {
                if (allPages[i].id == curPage.attr('id')) {
                    curIndex = i;
                }
            }

            if (curIndex > 0) {
                $prevPageButton.show();
            }

            if (curIndex < allPages.length - 1) {
                $nextPageButton.show();
                $submitButton.hide();
            } else if (curIndex == allPages.length - 1) {
                $nextPageButton.hide();
                $submitButton.show();
            }

            form.$el.find('#pagesTab li').hide();
            form.$el.find('#pagesTab li.conditionally-visible').show();
        }

        function addFormFieldsToParameters(fields, params) {
            fields.each(function () {
                var field = $(this);
                var fieldType = field.attr('type');
                var fieldName = field.attr('name');
                var checked = [];
                var checkedSelector = '.eforms-page.conditionally-visible:visible .form-field *:input[name="' + fieldName + '"]:checked';

                if (fieldType == 'checkbox') {
                    if (!params[fieldName]) {
                        checked = form.$el.find(checkedSelector);
                        if (checked.length > 0) {
                            var values = [];
                            checked.each(function (index) {
                                values[index] = $(this).val();
                            });
                            params[fieldName] = values;
                        } else {
                            params[fieldName] = '';
                        }
                    }
                } else if (fieldType == 'radio') {
                    if (!params[fieldName]) {
                        checked = form.$el.find(checkedSelector);
                        if (checked.length > 0) {
                            params[fieldName] = checked.val();
                        } else {
                            params[fieldName] = '';
                        }
                    }
                } else {
                    if ($(this).parents('.antispam').length) {
                        if ($(this.val) > 50) {
                            params[fieldName] = 100;
                        }
                    } else {
                        params[fieldName] = $(this).val();
                    }
                }
            });
        }

        function endsWith(subject, search) {
            var position = subject.length - search.length;
            var lastIndex = subject.indexOf(search, position);
            return lastIndex !== -1 && lastIndex === position;
        }

        function init() {
            if(form.$el.hasClass("form-reloaded")){
                return;
            }
            initAntiSpamSlider();

            form.$el.find('.checkboxgroup label.custom-control-label').each(function () {
                $(this).html($(this).text());
            });

            form.$el.validate({errorElement: 'div'});

            var fields = form.$el.find('.form-field *:input');

            fields.blur(function () {

                // on leaving form field, post field name/value for ajax validation
                var params = {};
                var field = $(this);
                var fieldName = field.attr('name');
                var fieldType = field.attr('type');
                var fieldValue = field.val();
                var otherSuffix = '-other';

                // Radio groups and checkboxes have an option for showing a textfield if users wish to provide a different value
                // than is provided through the checkboxes/radio buttons. This value is always named <fieldName>-other and will only
                // be validated correctly over Ajax if both the field that enables/disables the '-other' textfield as well as the
                // '-other' textfield itself is submitted.
                if (endsWith(fieldName, otherSuffix)) {
                    var prevFieldName = fieldName.substring(0, fieldName.length - otherSuffix.length);
                    // check if the radio/checkbox that enables this '-other' field is checked
                    var prevField = form.$el.find('.form-field *:input[name=' + prevFieldName + '][value=-other]:checked');
                    if (prevField.length) {
                        params[fieldName] = fieldValue;
                        params[prevFieldName] = otherSuffix;
                    }
                } else if (fieldType === 'checkbox') {
                    var checked = form.$el.find('.form-field *:input[name=' + fieldName + ']:checked');
                    if (checked.length > 0) {
                        var values = [];
                        checked.each(function (index) {
                            values[index] = $(this).val();
                        });
                        params[fieldName] = values;
                    } else {
                        params[fieldName] = '';
                    }
                } else {
                    params[fieldName] = fieldValue;
                }

                if (params.length === 0) {
                    // No fields to validate
                    return;
                }

                $.post(form.validationUrl, params,
                    function (data) {
                        var feedbackPanel = form.$el.find('#feedbackPanel');
                        var count = 0;
                        if (data) {
                            var messagesList = form.$el.find('#feedbackPanel > ul');
                            count = messagesList.children('li').length;
                            //indicates if we received any messages
                            var hasMessages = false;
                            for (var errorKey in data) {
                                if (data.hasOwnProperty(errorKey)) {
                                    // get the error message
                                    var errorMessage = data[errorKey];
                                    if (errorMessage) {
                                        hasMessages = true;
                                        messagesList.children('li').each(function () {
                                            var kid = $(this);
                                            var existing = kid.attr("data-field-name");
                                            if (errorKey === existing) {
                                                kid.remove();
                                                count++;
                                            }
                                        });
                                        // add error message to feedback panel
                                        messagesList.append('<li data-field-name="' + errorKey + '">' + errorMessage.localizedMessage + '</li>')
                                        count++;
                                    }
                                }
                            }
                            if (!hasMessages && params) {
                                messagesList.children('li').each(function () {
                                    var kid = $(this);
                                    var existing = kid.attr("data-field-name");
                                    for (var key in params) {
                                        if (params.hasOwnProperty(key)) {
                                            if (existing === key) {
                                                kid.remove();
                                            }
                                        }
                                    }
                                });
                            }
                        }
                        if (count > 0) {
                            // make feedback panel visible
                            feedbackPanel.show();
                        } else {
                            // make feedback panel invisible
                            feedbackPanel.hide();
                        }
                    }, "json");
            });

            var condFieldNames = {};

            if (form.conditions) {
                var items = [];
                if (form.conditions['fields']) {
                    items = items.concat(form.conditions['fields']);
                }
                if (form.conditions['pages']) {
                    items = items.concat(form.conditions['pages']);
                }
                for (var i = 0; i < items.length; i++) {
                    var item = items[i];
                    var condFieldName = item['condname'];
                    if (!condFieldNames[condFieldName]) {
                        condFieldNames[condFieldName] = true;
                    }
                }
            }

            for (var condFieldName in condFieldNames) {
                var condField = form.$el.find('.form-field *[name="' + condFieldName + '"]');
                if (condField.length == 0) continue;
                var eventType = 'change';

                condField.bind(eventType, function () {
                    if (form.conditions && form.conditions['fields']) {
                        var fields = form.conditions['fields'];

                        for (var i = 0; i < fields.length; i++) {
                            var field = fields[i];
                            var condFieldName = field['condname'];
                            if ($(this).attr('name') != condFieldName) continue;

                            var name = field['name'];
                            var targetField = form.$el.find('.form-field *[name="' + name + '"]');
                            if (targetField.length == 0) {
                                targetField = form.$el.find('.eforms-fieldgroup[name="' + name + '"]');
                            }
                            if (targetField.length == 0) {
                                targetField = form.$el.find('.eforms-text[name="' + name + '"]');
                            }
                            if (targetField.length == 0) continue;

                            var targetContainer = targetField.parents('.form-group');
                            if (targetContainer.length == 0) {
                                targetContainer = targetField;
                            }

                            var type = field['condtype'];
                            var condFieldValue = field['condvalue'];
                            var condNegated = field['condnegated'];
                            var curSelectedValue = $(this).val();
                            if ($(this).is('input') && $(this).attr('type') == 'radio') {
                                curSelectedValue = form.$el.find('.form-field *[name="' + condFieldName + '"]:radio:checked').val();
                            }

                            if (type == 'visibility') {
                                if ((!condNegated && condFieldValue == curSelectedValue) || (condNegated && condFieldValue != curSelectedValue)) {
                                    targetContainer.show();
                                } else {
                                    targetContainer.hide();
                                }
                            }
                        }

                        var pages = form.conditions['pages'];
                        for (var i = 0; i < pages.length; i++) {
                            var page = pages[i];
                            var condFieldName = page['condname'];
                            if ($(this).attr('name') != condFieldName) continue;

                            var pageIndex = page['index'];
                            var targetPage = form.$el.find('#page' + pageIndex);
                            var type = page['condtype'];
                            var condFieldValue = page['condvalue'];
                            var condNegated = page['condnegated'];
                            var curSelectedValue = $(this).val();
                            if ($(this).is('input') && $(this).attr('type') == 'radio') {
                                curSelectedValue = form.$el.find('.form-field *[name="' + condFieldName + '"]:radio:checked').val();
                            }

                            if (type == 'visibility') {
                                if ((!condNegated && condFieldValue == curSelectedValue) || (condNegated && condFieldValue != curSelectedValue)) {
                                    targetPage.addClass('conditionally-visible');
                                    form.$el.find('#pagesTab li:nth-child(' + (pageIndex + 1) + ')').addClass('conditionally-visible');
                                } else {
                                    targetPage.removeClass('conditionally-visible');
                                    form.$el.find('#pagesTab li:nth-child(' + (pageIndex + 1) + ')').removeClass('conditionally-visible');
                                }
                                resetPagesVisible();
                            }
                        }
                    }
                });

                condField.trigger(eventType);
            }

            if (form.$el.find('#pagesTab')) {
                form.$el.find('#pagesTab').show();
                $submitButton.hide();
            }

            form.$el.find('.eforms-page').each(function () {
                $(this).hide();
            });

            if (form.$el.find('.eforms-page.conditionally-visible').length) {
                form.$el.find('.eforms-page.conditionally-visible:first').show();
            }

            var $confirmationMsg = form.$el.parent().find('.eform-confirmation-msg');
            if ($confirmationMsg.length) {
                $('html, body').animate({
                    scrollTop: $confirmationMsg.offset().top - 200
                }, 500);
            }

            resetPagesVisible();

            $prevPageButton.find('input, button').click(function () {
                let curPage = form.$el.find('.eforms-page.conditionally-visible:visible');
                if(curPage && curPage.length > 0) {
                    let curIndex = parseInt(curPage.attr('id').replace(/^page/, ''));
                    if (curIndex > 0) {

                        let prevPage = curPage.prevAll('.eforms-page.conditionally-visible:first');
                        let prevIndex = parseInt(prevPage.attr('id').replace(/^page/, ''));

                        form.$el.find('#pagesTab li:nth-child(' + (curIndex + 1) + ')').removeClass('selected');
                        form.$el.find('#pagesTab li:nth-child(' + (prevIndex + 1) + ')').addClass('selected');

                        prevPage.show();
                        curPage.hide();

                        if (prevPage.prevAll('.eforms-page.conditionally-visible:first').length === 0) {
                            $prevPageButton.hide();
                        }

                        $nextPageButton.show();
                        $submitButton.hide();

                        // remove error messages from feedback panel
                        let messagesList = form.$el.find('#feedbackPanel > ul');
                        messagesList.empty();

                        // hide feedbackPanel
                        let feedbackPanel = form.$el.find('#feedbackPanel');
                        feedbackPanel.hide();

                        initAntiSpamSlider();
                    }
                }
            });

            $nextPageButton.find('input, button').click(function () {
                var curPage = form.$el.find('.eforms-page.conditionally-visible:visible');

                // ajax based validation
                // validate all fields on current page before going to the next
                var params = {};
                var fieldsOnPage = form.$el.find('.eforms-page.conditionally-visible:visible .form-field:visible *:input');
                addFormFieldsToParameters(fieldsOnPage, params);

                // add an empty parameter for any group on the current page
                var groupsOnPage = form.$el.find('.eforms-page.conditionally-visible:visible .form-group:visible');
                groupsOnPage.each(function () {
                    params[$(this).attr('name')] = '';
                });

                // add current page index to parameters
                params['currentPage'] = curPage.attr('id').replace(/^page/, '');

                setLoadingButton($nextPageButton, true);

                $.post(form.validationUrl, params,
                    function (data) {

                        var count = applyValidationErrors(data);
                        setLoadingButton($nextPageButton, false);

                        var feedbackPanel = form.$el.find('#feedbackPanel');
                        if (count > 0) {
                            valid = false;
                            // there are validation errors, make feedback panel visible
                            feedbackPanel.show();
                            setLoadingButton($nextPageButton, false);
                            setLoadingButton($submitButton, false);
                        } else {
                            // no error messages, make feedback panel invisible
                            feedbackPanel.hide();

                            // go to the next page
                            var nextPage = curPage.nextAll('.eforms-page.conditionally-visible:first');
                            window.nextPage = nextPage
                            nextPage.show();
                            let formOffsetTop = nextPage.parents('#eform-component')[0].offsetTop
                            let menuHeight = isMobile()? 60 : 160
                            window.scroll(0,formOffsetTop - menuHeight)
                            curPage.hide();

                            var curIndex = parseInt(curPage.attr('id').replace(/^page/, ''));
                            var nextIndex = parseInt(nextPage.attr('id').replace(/^page/, ''));
                            form.$el.find('#pagesTab li:nth-child(' + (curIndex + 1) + ')').removeClass('selected');
                            form.$el.find('#pagesTab li:nth-child(' + (nextIndex + 1) + ')').addClass('selected');

                            initAntiSpamSlider();

                            $prevPageButton.show();
                            if (nextPage.nextAll('.eforms-page.conditionally-visible:first').length == 0) {
                                $nextPageButton.hide();
                                $submitButton.show();
                            }
                        }

                    }, "json");
            });

            var valid = false;
			var email;

            // ajax page validation in case of last (or only) page
            form.$el.submit(function (event) {

                setLoadingButton($submitButton, true);

                var curPage = form.$el.find('.eforms-page.conditionally-visible:visible');

                // check attachments
                if(form.$el.find(".eforms-hint.eforms-error").length > 0){
                    setLoadingButton($submitButton, false);
                }
                // if valid flag is set, page was validated and form can be submitted
                if (valid) {
					// connectif event
					if (email && window.connectif && window.connectif.managed && window.connectif.managed.isInitialized()){

						window.connectif.managed.sendEvents([],
							{
							  entityInfo: {
								primaryKey: email
							  }
							}
						);
					}
                    return true;
                }

                var params = {};
                var fieldsOnPage = form.$el.find('.eforms-page.conditionally-visible:visible .form-field:visible *:input');
                addFormFieldsToParameters(fieldsOnPage, params);

                // add an empty parameter for any visible group on the current page
                var groupsOnPage = form.$el.find('.eforms-page.conditionally-visible:visible .form-group:visible');
                groupsOnPage.each(function () {
                    params[$(this).attr('name')] = '';
                });

                // add current page index to parameters
                params['currentPage'] = curPage.attr('id').replace(/^page/, '');

                // prevent form submission as we want to do ajax validation first
                event.preventDefault();

                $('#feedbackPanel').find('span').remove();

                // skip validation and behaviours if user email is blocked
                let formBehaviourBlocked = false;
                let thankYouPageUrl = form.afterProcessSuccessUrl;
                let blockedEmails = form.blockedEmails;
                let paramValues = Object.keys(params).map(key => params[key]);
                paramValues.forEach(p => {
                   if(p && p !== "" && blockedEmails && blockedEmails.includes(p)){
                       formBehaviourBlocked = true;
                   }
				   let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					if(!email && re.test(String(p).toLowerCase())){
						email = p;
					}
                });

                if(formBehaviourBlocked){
                    window.location.href = thankYouPageUrl;
                }else{
                    $.post(form.validationUrl, params,
                        function (data) {

                            var count = applyValidationErrors(data);
                            var feedbackPanel = form.$el.find('#feedbackPanel');
                            if (count > 0) {
                                valid = false;
                                // there are validation errors, make feedback panel visible
                                feedbackPanel.show();
                                setLoadingButton($submitButton, false);
                            } else {
                                // no error messages, make feedback panel invisible
                                feedbackPanel.hide();

                                // set valid flag and resubmit form
                                valid = true;
                                setLoadingButton($submitButton, false);
                                $submitButton.find('input, button').click();
                            }

                        }, "json")
                        .fail(function(response){
                            valid = false;
                            var feedbackPanel = $('#feedbackPanel');
                            feedbackPanel.append("<span style='color: red'>Some error occurred.</span>")
                            feedbackPanel.show();
                            setLoadingButton($submitButton, false);
                        });
                }
            });
            form.$el.addClass("form-reloaded");
        }

        if (form.modal) {

            var hasTimeout = form.modalTimeout && form.modalTimeout > 0;
            var $modal = form.$el.parents('.modal');
            $modal.modal({
                backdrop: true,
                keyboard: true,
                show: !hasTimeout
            });

            $modal.on('shown.bs.modal', init);
            if (hasTimeout) {
                setTimeout(function () {
                    $modal.modal('show');
                }, form.modalTimeout * 1000);
            }
        } else {

            init();
        }
    });
}

$(document).ready(function () {
    // function 'reloadForms()' is already invoked by 'ssc-form-manager.js'
    if($("#ssc-form").length === 0){
        reloadForms();
    }
});